$dropColor: #E8EDF5;
$secondaryColor: #515A6A;
$grayColor: #DDDFE2;


.public_opportunity {
  background-color: #FAFCFE;
  padding: 2% 5%;
  min-height: 92.5%;
  margin-top: 15px;
  position: relative;
}

.public_opportunity .search_btn {
  margin-top: 25px;
}

.public_opportunity .search_btn .btn_wrapper.large {
  height: 90%;
}

.public_opportunity .search_btn_1 {
  margin-top: 0;
}

.public_opportunity .section_main {
  background-color: #fff;
  border-radius: 20px;
}

.public_opportunity .opp_drops button{
  background-color: $dropColor!important;
  border: none;
  padding: 10px;
  font-size: 13px;
}

.public_opportunity .opp_drops button:hover{
  background-color: $dropColor!important;
}

.public_opportunity .three_dots_v_wrapper {
  padding: 5px;
  background: #DDDFE2;
  border-radius: 3px;
  height: 40px;
}

.public_opportunity .cards_grid.large {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  gap: 2%;
  margin-bottom: 30px;
}


.public_opportunity .cards_grid.resources.large {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  gap: 1%;
  margin-bottom: 30px;
}

.public_opportunity .cards_grid.small {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 48% 48%;
  column-gap: 2%;
  row-gap: 1%;
  margin-bottom: 30px;
}

.public_opportunity .cards_grid .card {
  padding: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.card_title {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

.card_description {
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.public_opportunity .cards_grid .card .card_main.mobile {
  display: none;
}

.public_opportunity .section_main_drops {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 13% 8% 10% 20% 22% 14%;
  gap: 1%;
}

.public_opportunity .section_main_drops.small {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 43% 28% 23%;
  column-gap: 1%;
  row-gap: 2%;
  width: 60%;
}

.card.active {
  border: 1.5px solid #10408C;
}

.public_opportunity .rightModal {
  background-color: #FAFCFE;
  // width: 75%;
  border: 1px solid #DDDFE2;
  border-radius: 8px 8px 0 0;
  height: max-content;
  position: sticky;
  min-width: 500px;
  top: 330px;
  right: 0;
}

.public_opportunity .close_btn {
  padding: 5px;
  background-color: $grayColor;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.public_opportunity .right_modal_icons {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #DDDFE2;
  background-color: rgba(244, 245, 247, 0.7);
}

.public_opportunity .opp_toggle_title {
  text-transform: uppercase;
  font-size: 18px;
}

.public_opportunity .opp_drops.three_dots button::after {
  display: none;
}

.modal-dialog.modal-lg.modal-dialog-centered {
  max-width: 500px;
}

.nav-tabs button[aria-selected=true] {
  color: #10408C!important;
  border-bottom: 2px solid #10408C;
  background-color: rgba(207, 217, 232, 0.2)!important;
  border-radius: 4px 4px 0px 0px;
  font-weight: 700;
}

.nav-tabs .nav-link {
  border: none;
}

.nav-tabs .nav-link.active {
  border-bottom: 2px solid #10408C;
}

.nav-tabs button {
  color: $secondaryColor;
}

#share_modal-tabpane-social_media .logo_wrapper {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #CFD9E8;
  width: 32%;
}

.apply_youth_modal .modal-body {
  padding: 0;
}

.globe_link_wrapper .btn_with_icon_wrapper.align_left {
  justify-content: center;
  background-color: #FFF4E1;
  border: 1px solid #FFC768;
}

.globe_link_wrapper .btn_with_icon_wrapper.align_left > span:last-child {
  margin-right: 10px;
}

.modal_top_bg {
  background-image: url('../../assets/images/bg_modal.png');
  height: 320px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
}

.modal_top_bg .close_btn {
  padding: 5px;
  background-color: $grayColor;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apply_youth_modal .modal_content {
  background-color: #fff;
  height: 100%;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.apply_youth_modal .modal_content .item_no {
  height: 30px;
  width: 30px;
  color: #fff;
  background-color: #10408C;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.push_item {
  margin-left: 5%;
  font-weight: 300;
}

.public_opportunity .main_section .section_header.mobile{
  display: none;
}

.public_opportunity .three_dots_share {
  padding: 0;
}

.opp_drops .dropdown-toggle::after {
  margin-left: 15px;
}

.apply_youth_modal .modal-dialog {
  max-width: 650px!important;
}

.share_modal .btn_with_icon_wrapper.secondary.align_left {
  width: 30%;
}

@media (max-width: 960px){
  .public_opportunity .rightModal {
    min-width: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
  }
}

@media (max-width: 760px) {
  .top_nav_btns {
    display: none;
  }

  .public_opportunity .main_section .section_header{
    display: none;
  }

  .public_opportunity .main_section .section_header.mobile{
    display: flex;
    flex-direction: column;
  }

  .section_header.mobile .top_sect_input > div:nth-child(1){
    margin-right: 0;
  }

  .section_header.mobile .top_input{
    background-color: #fff;
    padding: 5px;
    border-radius: 8px;
    border: 1px solid #DDDFE2;
    margin: 0 3%;
  }

  .section_header.mobile .top_input input{
    border: none;
  }

  .section_header.mobile .top_input input:focus{
    box-shadow: 0 0 0 0;
  }

  .section_header.mobile .top_input.second {
    padding: 10px;
  }

  .public_opportunity {
    padding: 2% 0 5%;
    min-height: 100%;
    background-color: #FAFCFE;
  }

  .public_opportunity .section_main{
    border-radius: 0;
    height: 100%;
  }

  .public_opportunity .section_main_drops {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 45% 25%;
    gap: 1%;
  }

  .public_opportunity .cards_grid.large,
  .public_opportunity .cards_grid.small,
  .public_opportunity .cards_grid.resources.large,
  .public_opportunity .cards_grid.resources.small{
    display: -ms-grid;
    display: grid;
    grid-template-columns: 98%;
    grid-template-rows: auto;
    row-gap: 20px;
    margin-bottom: 0;
    padding: 20px 0;
  }

  .public_opportunity .cards_grid .card .card_main.mobile {
    display: block;
  }

  .public_opportunity .cards_grid .card .card_main.large {
    display: none;
  }

  .public_opportunity .rightModal {
    min-width: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
  }

  .start_typing_input {
    border: 0 !important;
  }
}

.common-search-btn {
  padding: 10px 24px;
  gap: 8px;
  width: 187px;
  height: 48px;
  background: #10408C;
  border: none;
  border-radius: 6px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.dropdown-btn button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: #E8EDF5;
  border-radius: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #515A6A;
  white-space: nowrap;
}

.opportunity-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  width: 284px;
  background: #FFFFFF;
  border: 1px solid #DDDFE2;
  border-radius: 8px;
}

.opportunity-card.active {
  border: 1.5px solid #10408C;
}

.opportunity-card:hover {
  box-shadow: 0 8px 20px #2e394c29;
}

.opportunity-tag-badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  height: 24px;
  background: #DAF8EE;
  border: 1px solid #A1EED4;
  border-radius: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #226E54;
}

.opportunity-name {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #2E394C;
  margin: 0;
}

.opportunity-owner {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #515A6A;
}

.opportunity-description {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #515A6A;
}

.opportunity-reviews-count {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #515A6A;
}

.opportunity-detail-owner {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 17.0625px;
  line-height: 24px;
  color: #262F3F;
  margin: 0;
}

.opportunity-detail-state {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 14.4375px;
  line-height: 18px;
  color: #969CA5;
  margin: 0;
}

.opportunity-detail-name {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 28.1739px;
  line-height: 38px;
  color: #262F3F;
}

.opportunity-detail-posted {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #515A6A;
}

.opportunity-detail-expiry {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #AA8545;
}

.opportunity-detail-recommend-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 310px;
  height: 44px;
  padding: 10px !important;
  background: #10408C !important;
  border-radius: 8px !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.02em !important;
  color: #FFFFFF !important;
  white-space: nowrap;
}

.opportunity-detail-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px !important;
  gap: 10px;

  width: 44px;
  height: 44px;
  background: rgba(244, 245, 247, 0.7) !important;
  border: 1px solid #DDDFE2 !important;
  border-radius: 8px !important;
}

.opportunity-detail-btn.save {
  background: #DAF8EE !important;
  border: 1px solid #A1EED4 !important;
  color: #226E54 !important;
}

.opportunity-detail-btn.options {
  background: rgba(244, 245, 247, 0.7) !important;
  border: 1px solid #DDDFE2 !important;
  color: #515A6A !important;
}

.opportunity-detail-group-intro {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #2E394C;
}

.opportunity-detail-description {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-secondary);
}

.btn-view-profile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;
  height: 38px;
  background: #F4F5F7;
  border: 1px solid #DDDFE2 !important;
  border-radius: 6px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #2E394C !important;
  white-space: nowrap;
}

.resource-name {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #515A6A;
  margin: 0;
}

.resource-category {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #969CA5;
  margin: 0;
}

.resource-location {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #969CA5;
}

.resource-connections {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #969CA5;
}

.resource-connections-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 15px;
  background-color: #10408C;
}

.resource-connections i {
  color: white;
  font-size: 9px;
}

.resource-connections-count {
  color: #10408C;
}

.mentor-address {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #515A6A;
}

.mentor-school-name {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #262F3F;
  margin: 0;
}

.mentors-count {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #969CA5;
}

.btn-ellipsis {
  background: #F4F5F7;
  border-radius: 4px;
  color: #969CA5;
  border: 0;
}

@media screen and (max-width: 420px){
  .opportunity-card {
    width: 100%;
  }
  
  .opportunity-detail-owner {
    font-size: 0.9rem;
  }
}