

.common_top_sect {
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.25);
  padding: 0.5% 5%;
  z-index: 99;
  .middle_text {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #515A6A;
  }
  .top_section__heading {
    color: #2E394C;
    span {
      font-size: 25px;
      font-weight: 700;
    }
    sup {
      font-weight: 500;
      font-size: 16px;
      margin-left: 5px;
      margin-top: -5px;
    }
  }

  .login_drop {
    button{
      padding: 8px 12px;
      background: #10408C;
      border-radius: 8px;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: #FFFFFF;
    }
    button::after {
      display: none;
      background-color: #fff;
    }
  }  
  .mobile_nav_icon {
    display: none;
  }
}

.mobile_nav_modal {
  display: none;
}

.mobile_nav_modal .nav_links > div {
  font-size: 20px;
  margin-top: 5%;
}

.mobile_nav_modal .bottom_links > div {
  padding: 10px;
  color: #fff;
  border-radius: 8px;
}

.mobile_nav_modal .bottom_links > div:nth-child(1){
  background-color: #AA8545;
}

.mobile_nav_modal .bottom_links > div:nth-child(2){
  background-color: #38609F;
}

.mobile_nav_modal .bottom_links > div:nth-child(3){
  background-color: #39B88D;
}

.mobile_nav_modal .nav_links_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}


@media (max-width: 768px) {
  .common_top_sect .middle_text,
  .common_top_sect .login_btn {
    display: none;
  }

  .common_top_sect .mobile_nav_icon {
    display: flex;
  }

  .comps_wrapper {
    width: 50%;
    text-align: center;
  }

  .comps_wrapper .btn_wrapper.yellow_btn {
    width: 100px;
    margin: 0 25% 30px;
  }
}

.css-13cymwt-control {
  height: 48px;
}

.react-select__value-container {
  background-color: red;
  color: green;
}

.css-1p3m7a8-multiValue {
  background-color: #FFE3B3!important;
}

.mobile-location-select {
  width: 100%;
}

.input-container {
  padding: 8px;
  background: #FFFFFF;
  border: 1px solid #DDDFE2;
  border-radius: 8px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #969CA5;

  .custom-form-input,
  .custom-form-input:focus {
    border: none;
    outline: none;
    box-shadow: none;
    height: auto;
  }

  .filtered-locations {
    padding: 8px;
    gap: 8px;
    background: #FFFFFF;
    border: 1px solid #DDDFE2;
    border-radius: 8px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #969CA5;
    box-shadow: var(--bs-box-shadow);

    .filtered-location:hover {
      color: #2E394C
    }
  }

}