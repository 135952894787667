//@media screen and (max-width: 761px) {
//    .page_wrapper {
//        overflow: auto!important;
//    }
//}
//
@media screen and (min-width: 761px) {
  //.page_wrapper {
  //    overflow: hidden!important;
  //}
  .public_opportunity {
    padding: 0;
  }
  .sticky-search-filters {
    position: sticky;
    top: 70px;
    z-index: 7;
    padding: 2% 5% 1%;
    background: #fff
  }

  .three_dots {
    z-index: 2;
  }

  .section_main.opps_sect {
    margin: 2% 5%;
  }
  .opp-filter-row {
    height: 60px;
    position: sticky;
    top: 240px;
    z-index: 5;
    background: #fff;
    margin-top: 10px;
    width: 95%;
  }
  #react-select-3-listbox {
    z-index: 1000!important;
  }
  //.opp-cards {
  //  overflow: auto;
  //  height: 40vh;
  //  padding-bottom: 70px;
  //  .opportunity-card {
  //    max-height: 290px;
  //  }
  //}
}

.apply_youth_modal {
  padding-left: 0 !important;
}

.input-sub-text {
  font-size: 14px;
}

//.load-more {
//    width: 100%;
//    height: 50px; /* Adjust the height based on your design */
//    background-color: transparent;
//    margin-top: 20px; /* Adjust the margin based on your design */
//}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #10408C;
  color: #10408C;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #10408C;
  color: #10408C;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}