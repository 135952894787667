

.initials_wrapper {
  border-radius: 50%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.initials_wrapper.small {
  width: 30px;
  height: 30px;
  font-size: 15px;
}

.initials_wrapper.large {
  width: 50px;
  height: 50px;
  font-size: 20px;
}

.initials_wrapper.md {
  width: 40px;
  height: 40px;
  font-size: 20px;
}